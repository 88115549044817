.github-widget {
  font-family: Arial, sans-serif;
  border: 1px solid #444;
  padding: 20px;
  max-width: 100%;
  margin: 0 auto;
  background-color: #1e1e1e;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  color: #fff;
}

.github-widget h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #fff;
}

.userprofile {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.userprofile img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.userprofile p {
  font-weight: bold;
  font-size: 18px;
}

.repositories-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.repo {
  background-color: #2e2e2e;
  border: 1px solid #444;
  border-radius: 10px;
  padding: 15px;
  margin: 10px;
  flex: 1 1 calc(33.333% - 20px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.repo-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.repo-name {
  display: flex;
  align-items: center;
}

.repo-name a {
  font-weight: bold;
  font-size: 16px;
  color: #1e90ff;
  text-decoration: none;
  margin-right: 10px;
}

.repo-name a:hover {
  text-decoration: underline;
}

.repo-stars {
  font-size: 16px;
  color: gold;
  font-weight: bold;
}

.repo-details {
  display: flex;
  align-items: center;
}

.repo-language {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.language-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 5px;
}

.repo-description {
  color: #ccc;
  font-size: 14px;
  margin-bottom: 10px;
}

.repo-size,
.repo-updated {
  font-size: 12px;
  color: #aaa;
  margin-right: 10px;
}

.language-dot.c {
  background-color: #555;
}

.language-dot.cpp {
  background-color: #f34b7d;
}

.language-dot.css {
  background-color: #563d7c;
}

.language-dot.html {
  background-color: #e34c26;
}

.language-dot.java {
  background-color: #b07219;
}

.language-dot.javascript {
  background-color: #f1e05a;
}

.language-dot.php {
  background-color: #4f5d95;
}

.language-dot.python {
  background-color: #3572a5;
}

.language-dot.ruby {
  background-color: #701516;
}
