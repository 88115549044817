.about-me h1 {
    font-size: 2em;
    color: #fff;
    text-align: center;
    margin-bottom: 20px;
  }
  
.about-me p {
    font-size: 1em;
    color: #ccc;
    line-height: 1.6;
    text-align: left;
    margin-bottom: 10px;
  }
  