.header-container {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .fade-in {
    opacity: 0;
    animation: fadeIn 1s forwards;
  }
  
  .subtitle {
    margin-top: -40px;
    font-size: 1em;
    text-align: center;
    color: #666;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  