
.resume-content h2 {
    text-align: center;
  }
  
.resume-content h3,
.resume-content p,
.resume-content ul {
    text-align: left;
}
  
  
.resume-content {
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;
}

.resume-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
  
.resume-details {
    flex-basis: 70%;
}
  
.resume-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-items: right;
    text-align: right;
}
  
.resume-details {
    flex-basis: 70%;
    align-items: right;
    text-align: right;
}




@media screen and (max-width: 768px) {
    .box-container {
      flex-direction: row;
      align-items: flex-start;
    }
  
    .box {
      margin-right: 10px;
    }
  
    .contact-info {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }

.contact-info {
    flex-basis: 30%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: -2000px;
    align-items: right;
    text-align: right;
 }

.contact-box {
    background-color: #f2f2f2;
    border: 2px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    width: 300px;
    margin-bottom: 20px;
    align-items: right;
    text-align: right;
  }
  
  .contact-box h2 {
    margin-top: 0;
  }
  
  .contact-box h3 {
    margin-bottom: 10px;
  }
  
  .contact-box p {
    margin: 0;
  }
  
.authorized {
    color: red;
    font-style: italic;
}

.box-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  align-items: right;
  text-align: right;
}

  .box-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    align-items: right;
    text-align: right;
  }

  .text-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }