.terminal {
    background-color: #000000;
    color: #ffffff;
    padding: 20px;
    font-family: monospace;
    font-size: 16px;
    width: 80%;
    max-width: 800px;
    height: 400px;
    overflow: auto;
    margin: 50px auto;
    border: 2px solid #ffffff;
    border-radius: 4px;
    margin-bottom: 200px;
  }

  .terminal pre {
    white-space: pre-wrap;
    margin: 0;
    text-align: left;
  }
  
  .terminal pre span {
    position: relative;
  }
  
  .terminal pre .underline {
    text-decoration: underline;
    color: red;
    font-weight: bold;
  }