html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}

.App {
  text-align: center;
  height: 100vh;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.App::-webkit-scrollbar {
  display: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  position: relative;
}

.fade-out {
  opacity: 0;
  transition: opacity 1s ease-out;
}

.fade-in {
  opacity: 1;
  transition: opacity 1s ease-in;
}

.fade-transition {
  transition: opacity 1s;
}

.fade-element {
  transition: opacity 0.5s ease-in-out;
}

.contact-link {
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  color: white;
  cursor: pointer;
  position: fixed;
  top: 10px;
  right: 10px;
}

.contact-link:hover {
  text-decoration: underline;
}

.text-container {
  margin-top: 20px;
  text-align: center;
}


