.box:hover {
  transform: scale(1.1);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
  background-color: #444;
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
}

.box.active {
  background-color: #666;
  transform: scale(1.1);
}

.box h3 {
  margin: 0;
  color: #fff;
  font-size: 1.2em;
}

.box p {
  margin-top: 20px;
  color: #bbb;
}

.box-container {
  display: flex;
  justify-content: space-evenly;
  margin-top: 100px;
  z-index: 1;
}

.box {
  width: 220px;
  height: 220px;
  border-radius: 10px;
  border: 2px solid #444;
  margin-right: 15px;
  margin-top: 50px;
  background-color: #222;
  cursor: pointer;
  overflow: hidden;
  transition: transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease;
}

.box-content {
  padding: 20px;
  text-align: center;
}

.box:hover {
  background-color: #333;
}
